import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {CONSTANTS} from '../CONSTANATS';
import {HttpClient} from '../../../node_modules/@angular/common/http';
import {User} from '../models/User.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public API = CONSTANTS.API_URL + 'jwt-auth/v1/token';

    authenticationState = new BehaviorSubject(false);

    constructor(private storage: Storage,
                private plt: Platform,
                private httpClient: HttpClient) {
        this.plt.ready().then(() => {
            this.checkToken();
        });
    }

    login(user: User) {
        return this.httpClient.post<any>(
            this.API, user
        );
    }

    checkToken() {
        this.storage.get(CONSTANTS.JWT_TOKEN).then(res => {
            if (res) {
                this.authenticationState.next(true);
            }
        });
    }

    logout() {
        return this.storage.remove(CONSTANTS.JWT_TOKEN).then(() => {
            this.authenticationState.next(false);
        });
    }

    isAuthenticated() {
        return this.authenticationState.value;
    }
}
