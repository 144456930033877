export class CONSTANTS {
    /*
    static SERVER_URL = 'https://www.golsetan.com/';
    static API_URL = 'https://www.golsetan.com/wp-json/';
        static SERVER_URL = 'http://localhost/';
    static API_URL = 'http://localhost/wp-json/';
     */
    /*static SERVER_URL = 'http:/localhost/golsetan/';
    static API_URL = 'http:/localhost/golsetan/wp-json/';*/
    static SERVER_URL = 'https://www.golsetan.com/';
    static API_URL = 'https://www.golsetan.com/wp-json/';
    static VERSION = '1.0.';

    static JWT_TOKEN = 'ljjbnhvg';
    static USER_TYPE = 'hhnuih';
}
